import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import { useI18next } from "../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "@reach/router";
import * as Yup from "yup";

import { container, draw, fieldset, form, start } from "./generator.module.scss";
import {
    EGeneratorFilterKeys,
    IGeneratorFormValues,
    IGeneratorProductsSetPayload,
    TGeneratorFilters,
} from "../../../models/generator.model";
import { TStatus } from "../../../models/status.model";
import {
    selectGeneratorProducts,
    selectGeneratorProductsStatus,
} from "../../../redux/generator-products/generator-products.selectors";
import {
    clearGeneratorFiltersAction,
    getGeneratorFiltersAction,
} from "../../../redux/generator-filters/generator-filters.actions";
import {
    selectGeneratorFilters,
    selectGeneratorFiltersStatus,
} from "../../../redux/generator-filters/generator-filters.selectors";
import {
    clearGeneratorProductsAction,
    getGeneratorProductsAction,
} from "../../../redux/generator-products/generator-products.actions";
import { createSingleStylizationAction } from "../../../redux/single-stylization/single-stylization.actions";
import { selectSingleStylizationStatus } from "../../../redux/single-stylization/single-stylization.selectors";

import FormikForm from "../../../components/hoc/formik-form";
import GeneratorStartFields from "../organisms/generator-start-fields";
import GeneratorDrawFields from "../organisms/generator-draw-fields";
import Loader from "../../../components/atoms/loader";

interface IGeneratorLocationState {
    isSurveySuccessRedirect?: boolean;
}

const Generator: React.FC = () => {
    const { t } = useI18next();
    const location = useLocation();
    const locationState = location.state as IGeneratorLocationState | undefined;
    const isSurveySuccessRedirect = locationState?.isSurveySuccessRedirect;
    const initialName = isSurveySuccessRedirect ? t("generator.stylization.first") : "";
    const dispatch = useDispatch();
    const filters: TGeneratorFilters = useSelector(selectGeneratorFilters);
    const filtersStatus: TStatus = useSelector(selectGeneratorFiltersStatus);
    const { sessionId, sessionGuid, items }: IGeneratorProductsSetPayload = useSelector(selectGeneratorProducts);
    const productsStatus: TStatus = useSelector(selectGeneratorProductsStatus);
    const stylizationStatus: TStatus = useSelector(selectSingleStylizationStatus);
    const [initialFormValues, setInitialFormValues] = useState(
        getFormValues({ ...initialValues, name: initialName }, filters)
    );

    const isLoading = getLoadingStatus(filtersStatus, productsStatus, stylizationStatus, sessionId);

    const handleSubmit = (values: IGeneratorFormValues) => {
        dispatch(getGeneratorProductsAction(values));
    };

    const handleSave = (values: IGeneratorFormValues) => () => {
        dispatch(
            createSingleStylizationAction({
                name: values.name,
                guid: sessionGuid,
                //productIds: items.map((product) => product.productId),
                occasionId: values[EGeneratorFilterKeys.occasion],
            })
        );
    };

    useEffect(() => {
        dispatch(getGeneratorFiltersAction());
        return () => {
            dispatch(clearGeneratorFiltersAction());
            dispatch(clearGeneratorProductsAction());
        };
    }, [dispatch]);

    useEffect(() => {
        setInitialFormValues(getFormValues({ ...initialValues, name: initialName }, filters));
    }, [filters]);

    return (
        <div className={container}>
            {isLoading && <Loader position="fixed" />}
            <Formik
                onSubmit={handleSubmit}
                initialValues={initialFormValues}
                enableReinitialize={true}
                validationSchema={getValidationSchema(t)}
            >
                {(formik) => (
                    <FormikForm
                        formik={formik}
                        successMessage=""
                        className={form}
                        fieldsetClassName={fieldset}
                        loaderType="none"
                    >
                        {sessionId ? (
                            <GeneratorDrawFields
                                className={draw}
                                filters={filters}
                                products={items}
                                onSpaceKeydown={formik.submitForm}
                                onSave={handleSave(formik.values)}
                            />
                        ) : (
                            <GeneratorStartFields className={start} filters={filters} />
                        )}
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
};

const initialValues: IGeneratorFormValues = {
    name: "",
    blockedProducts: [],
    [EGeneratorFilterKeys.colors]: [],
    [EGeneratorFilterKeys.patterns]: [],
    [EGeneratorFilterKeys.occasion]: "",
    [EGeneratorFilterKeys.style]: "",
    [EGeneratorFilterKeys.priceTop]: "",
    [EGeneratorFilterKeys.priceBottom]: "",
    [EGeneratorFilterKeys.priceOuterwear]: "",
    [EGeneratorFilterKeys.priceOverall]: "",
};

function getValidationSchema(t: ReturnType<typeof useI18next>["t"]) {
    return Yup.object({
        name: Yup.string().required(t("form.validation.required")),
        [EGeneratorFilterKeys.occasion]: Yup.string().required(t("form.validation.required")),
        [EGeneratorFilterKeys.style]: Yup.string().required(t("form.validation.required")),
        [EGeneratorFilterKeys.priceTop]: Yup.lazy((value) =>
            typeof value === "number"
                ? Yup.number().required(t("form.validation.required"))
                : Yup.string().required(t("form.validation.required"))
        ),
        [EGeneratorFilterKeys.priceBottom]: Yup.lazy((value) =>
            typeof value === "number"
                ? Yup.number().required(t("form.validation.required"))
                : Yup.string().required(t("form.validation.required"))
        ),
        [EGeneratorFilterKeys.priceOuterwear]: Yup.lazy((value) =>
            typeof value === "number"
                ? Yup.number().required(t("form.validation.required"))
                : Yup.string().required(t("form.validation.required"))
        ),
        [EGeneratorFilterKeys.priceOverall]: Yup.lazy((value) =>
            typeof value === "number"
                ? Yup.number().required(t("form.validation.required"))
                : Yup.string().required(t("form.validation.required"))
        ),
    });
}

type TValuesFromFilters = Partial<
    Record<keyof IGeneratorFormValues, string | number | string[] | number[]>
>;

function getFormValues(
    initialValues: IGeneratorFormValues,
    filters: TGeneratorFilters
): IGeneratorFormValues {
    const keys = Object.keys(filters) as Array<keyof TGeneratorFilters>;
    const values = keys.reduce((result: TValuesFromFilters, key) => {
        const appliedOptions = filters[key].filter((option) => option.applied);
        const isArray = Array.isArray(initialValues[key]);
        let keyValue;
        if (isArray) {
            keyValue = appliedOptions
                .map((option) => {
                    return option.value || option.id;
                })
                .filter((value) => !!value) as string[] | number[];
        } else {
            keyValue = appliedOptions[0]?.value || appliedOptions[0]?.id;
        }
        if (keyValue) {
            result[key] = keyValue;
        }
        return result;
    }, {});
    return {
        ...initialValues,
        ...values,
    } as IGeneratorFormValues;
}

function getLoadingStatus(
    filtersStatus: TStatus,
    productsStatus: TStatus,
    stylizationStatus: TStatus,
    sessionId: number | null
): boolean {
    if (!filtersStatus || filtersStatus === "idle" || filtersStatus === "loading") return true;
    if (productsStatus === "loading" && !sessionId) return true;
    return stylizationStatus === "loading";
}

export default Generator;
